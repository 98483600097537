import React, { useState, useEffect, useRef, useContext } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import * as Yup from "yup"
import { ethers } from "ethers"
import MetaMaskOnboarding from "@metamask/onboarding"
import detectEthereumProvider from "@metamask/detect-provider"
import Layout from "../components/layoutMin"
import Seo from "../components/seo"
import Input from "../components/Input"
import { AuthContext } from "../context/auth"

const NftPfp = ({ location }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const { setUser } = useContext(AuthContext)
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const authToken = params.get("token")
    setToken(authToken)
    setData({
      token: authToken,
    })
  }, [location])

  const form = {
    title: "Connect Your Wallet",
    action: "Log In",
    isSuccess,
    isError,
    onSubmit: () => console.log("hi8"),
    validationSchema: Yup.object({
      email: Yup.string().email("Must be an email").required("Required"),
      password: Yup.string().required("Required"),
    }),
    // eslint-disable-next-line no-undef

    fields: [
      {
        name: "email",
        label: "E-mail",
        placeholder: "ie. john.doe@email.com",
        type: "email",
        initialValue: "",
      },
      {
        name: "password",
        label: "Password",
        placeholder: "Password",
        type: "password",
        initialValue: "",
      },
    ],

    authActions: [
      { text: "Forgot Password?", to: "password-reset" },
      { text: "Don't have an Account?", to: "register" },
    ],
  }
  const [loading, setLoading] = useState(null)
  const [success, setSuccess] = useState(null)
  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [tokenId, setTokenId] = useState("")
  const [wallet, setWallet] = useState("")
  const [token, setToken] = useState("")
  const [step, setStep] = useState(0)
  const [state, setState] = useState({
    provider: "undefined",
    account: "",
    selectedFile: null,
    ipfsHash: "",
    readyToMint: false,
    transactionHash: "",
  })
  const [accounts, setAccounts] = useState([])
  const onboarding = useRef()
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding()
    }
  }, [])

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        onboarding.current.stopOnboarding()
        setStep(1)
        saveAccount(accounts[0])
      }
    }
  }, [accounts])
  const saveAccount = account => {
    setWallet(account)
  }
  useEffect(() => {
    // if (wallet) {
    //   fetch(`https://api.opensea.io/api/v1/asset/${wallet}/1/`, {
    //     method: "GET",
    //   })
    //     .then(res => res.json())
    //     .then(res => console.log(res))
    // }
  }, [wallet])

  useEffect(() => {
    async function anonLoad() {
      loadProvider()
    }
    anonLoad()
  }, [])

  const loadProvider = async () => {
    const metaMaskProvider = await detectEthereumProvider()
    if (metaMaskProvider) {
      const provider = new ethers.providers.Web3Provider(metaMaskProvider)
      setState(state => ({ ...state, provider: provider }))
    } else {
      alert("MetaMask is NOT installed!")
    }
  }
  const connectMM = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(newAccounts => setAccounts(newAccounts))
    } else {
      onboarding.current.startOnboarding()
    }
  }
  const verifyOwnership = () => {
    if (!wallet || !tokenId) {
      setError("Please enter a Token ID")
    }
    const options = { method: "GET" }

    fetch(
      `https://api.opensea.io/api/v1/asset/0x99cd66b3D67Cd65FbAfbD2fD49915002d4a2E0F2/${tokenId}/?account_address=${wallet}`,
      options
    )
      .then(response => response.json())
      .then(response => {
        if (response.owner.address === wallet) {
          setData(data => ({
            ...data,
            profilePictureURL: response.image_preview_url,
            nftPfp: response.permalink,
          }))
          setError(null)
          setStep(2)
        } else {
          setError("This wallet does not own this asset")
        }
      })
      .catch(err => console.error(err))
  }
  const handleSubmit = async () => {
    setLoading(true)
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }
      const res = await fetch(
        `https://us-central1-discovr-98d5c.cloudfunctions.net/setNftPfp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
      if (res.status == 200) {
        setStep(3)
        setLoading(false)
      }
    } catch (err) {
      console.error(err)
    }
  }
  const steps = [
    {
      title: "Step 1:",
      cta: "Connect MetaMask",
      onClick: connectMM,
    },
    {
      title: "Step 2:",
      cta: "Verify Ownership",
      input: "tokenId",
      onClick: verifyOwnership,
    },
    {
      title: "Step 3:",
      cta: loading ? "Loading..." : "Complete",
      image: data?.profilePictureURL,
      onClick: handleSubmit,
    },
    {
      title: "Finished!",
      cta: "Return",
      onClick: () => navigate("/"),
    },
  ]
  return (
    <Layout>
      <Seo title="Nft Pfp" />
      <div>
        <h2 style={{ color: "#FFF" }}>Verified MEV Army Pfp</h2>
        <FormWrapper>
          <h3>{steps[step].title}</h3>
          {steps[step].input ? (
            <Input
              value={tokenId}
              name="tokenId"
              error={error}
              onChange={e => setTokenId(e.target.value)}
              placeholder="Enter token ID"
            />
          ) : null}
          {steps[step].image ? (
            <img
              src={steps[step].image}
              style={{
                width: "100%",
                height: "100%",
                aspectRatio: 230 / 250,
              }}
              alt="MEV army Nft"
            />
          ) : null}
          <BtnWrapper style={{ marginTop: 12 }} onClick={steps[step].onClick}>
            {steps[step].cta}
          </BtnWrapper>
        </FormWrapper>
      </div>
    </Layout>
  )
}
export const BtnWrapper = styled.button`
  position: relative;
  display: inline-block;
  margin: 0px;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border: none;
  outline: 0px;
  font-family: "Circular", sans-serif;
  letter-spacing: -0.028em;
  font-size: 17px;
  line-height: 1.6;
  font-weight: 600;
  padding: 14px 32px;
  white-space: nowrap;
  border-radius: 8px;
  transition: all 0.2s ease-in-out 0s;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};

  :hover,
  :focus {
    background-color: ${props => props.theme.colors.shades.secondary[1]};
  }

  :disabled,
  [disabled] {
    background-color: ${props => props.theme.colors.gray[5]};
    opacity: 70%;
    pointer-events: none;
  }
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  padding: 2em;
  border-radius: 8px;
  width: 90vw;
  form {
    width: 100%;
    margin-bottom: 0;
    div.form_items {
    }
  }
  .error {
    margin-bottom: 0;
    margin-top: 0.5em;
    color: ${props => props.theme.colors.error};
  }
  .message {
    margin-bottom: 0;
    margin-top: 0.5em;
    color: ${props => props.theme.colors.success};
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 400px;
  }
`
export default NftPfp
